<template>
	<div v-if="computedValue === `ko`">
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="container--center userguide">
			<div class="servicecontent__title">
				<h2>이용안내</h2>
				<span>서비스 이용 절차</span>
			</div>
			<div class="userguide__processbox">
				<div class="userguide__processbox--description">
					<i class="fas fa-phone-volume fa-4x"></i>
					<span>일반회원</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-envelope-open-text fa-4x"></i>
					<span>기업회원 전환 신청</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-sign-in-alt fa-4x"></i>
					<span>서비스 구매 및 상담</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-download fa-4x"></i>
					<span>솔루션 구축 및 이용</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="far fa-check-circle fa-4x"></i>
					<span>완료<br />(서비스 오픈)</span>
				</div>
			</div>
			<div class="userguide__processdetail">
				<div class="userguide__processdetail--text">
					<h3>일반회원</h3>
					<ul>
						<li>
							일반 회원은 기업 회원으로 전환한 후에 서비스를 구매할 수 있습니다.
						</li>
						<li>
							관련 문의 사항이 있을 경우 1:1 문의하기로 문의해주시기 바랍니다.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<router-link class="route-button" to="/inquiry"
							>1:1 문의</router-link
						>
						<router-link class="route-button" to="/faq"
							>자주 하는 질문</router-link
						>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>기업회원 전환 신청</h3>
					<ul>
						<li>
							기업 회원 전환 신청하면 FCTS에서 검토 후에 기업회원으로
							전환됩니다. (영업일 기준 3 ~ 5일 소요)
						</li>
						<li>
							검토 단계에서 추가적으로 서류가 필요할 시에 요청할 수 있습니다.
						</li>
					</ul>
					<ul>
						<li>
							<strong>온라인 접수:</strong> 아래 기업 전환 양식 파일을 다운로드
							후 작성하여 담당자 이메일(khjaay@four-chains.com)로 제출합니다.
						</li>
						<li>
							<strong>오프라인 접수 :</strong> 담당자와 연락 후, 우편(빠른 등기)
							또는 팩스로 제출합니다.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<router-link class="route-button" to="/userauth">
							기업회원 전환 작성 방법
						</router-link>
						<button class="btn--md btn--fill-accent" @click="download('1')">
							기업회원 전환 양식
						</button>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>서비스 구매 및 상담</h3>
					<ul>
						<li>기업회원 전환 후 즉시 서비스 구매가 가능합니다.</li>
						<li>
							구매 관련 문의 사항이 있으실 경우 1:1 문의하기로 문의 해주시기
							바랍니다.
						</li>
					</ul>
				</div>

				<div class="userguide__processdetail--text">
					<h3>솔루션 구축 및 이용</h3>
					<ul>
						<li>개발자 사이트 이동 후 API 연동 및 이용 방식을 확인합니다.</li>
						<li>
							서비스 이용에 불편이 있으실 경우 1:1 문의하기로 문의 해주시기
							바랍니다.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<router-link class="route-button" to="/warning"
							>개발자 사이트</router-link
						>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>5. 완료 (서비스 오픈)</h3>
					<ul>
						<li>
							주기적으로 업데이트 및 개발자 사이트 확인 하여 최신 으로 업데이트
							및 유지 해주시기 바랍니다.
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Footer />
	</div>
	<div v-else-if="computedValue === `en`">
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="container--center userguide">
			<div class="servicecontent__title">
				<h2>User Guide</h2>
				<span>Service Usage Procedure</span>
			</div>
			<div class="userguide__processbox">
				<div class="userguide__processbox--description">
					<i class="fas fa-phone-volume fa-4x"></i>
					<span>General Member</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-envelope-open-text fa-4x"></i>
					<span>Application for Transition to Corporate Membership</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-sign-in-alt fa-4x"></i>
					<span>Service Purchase and Consultation</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-download fa-4x"></i>
					<span>Solution Implementation and Usage</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="far fa-check-circle fa-4x"></i>
					<span>Completion<br />(Service Launch)</span>
				</div>
			</div>
			<div class="userguide__processdetail">
				<div class="userguide__processdetail--text">
					<h3>General Member</h3>
					<ul>
						<li>
							General members can purchase services after transitioning to
							corporate membership.
						</li>
						<li>
							If you have any related inquiries, please contact us through the
							1:1 Contact form.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<router-link class="route-button" to="/inquiry"
							>1:1 Contact</router-link
						>
						<router-link class="route-button" to="/faq"
							>Frequently Asked Questions</router-link
						>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>Application for Transition to Corporate Membership</h3>
					<ul>
						<li>
							After applying for the transition to corporate membership, your
							application will be reviewed by FCTS and you will be transitioned
							to corporate membership. (This process typically takes 3 to 5
							business days.)
						</li>
						<li>
							If additional documents are required during the review process,
							you may be asked to provide them.
						</li>
					</ul>
					<ul>
						<li>
							<strong>Online Submission: </strong> Download and complete the
							corporate transition form below, then submit it to the designated
							email (khjaay@four-chains.com).
						</li>
						<li>
							<strong>Offline Submission: </strong> After contacting the
							representative, submit the documents by mail (via expedited
							registered mail) or fax.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<router-link class="route-button" to="/userauth">
							How to Complete the Corporate Membership Transition
						</router-link>
						<button
							class="btn--md btn--fill-accent"
							@click="download('1')"
							style="margin-top: 15px"
						>
							Corporate Membership Transition Form
						</button>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>Service Purchase and Consultation</h3>
					<ul>
						<li>
							Services can be purchased immediately after transitioning to
							corporate membership.
						</li>
						<li>
							If you have any inquiries regarding purchases, please contact us
							through the 1:1 Contact form.
						</li>
					</ul>
				</div>

				<div class="userguide__processdetail--text">
					<h3>Solution Implementation and Usage</h3>
					<ul>
						<li>
							After navigating to the developer site, you can check the API
							integration and usage methods.
						</li>
						<li>
							If you experience any issues while using the service, please
							contact us through the 1:1 Contact form.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<router-link class="route-button" to="/warning"
							>Developer Site</router-link
						>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>5. Completion (Service Launch)</h3>
					<ul>
						<li>
							Please regularly check for updates and the developer site to
							ensure everything is updated and maintained with the latest
							information.
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import downloadService from '../services/downloadService';
import Footer from '../components/Footer.vue';
import MainHeader from '../components/MainHeader.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
export default {
	components: { MainHeader, Footer, MenuIntro },
	data() {
		return {
			// menuKr: '이용안내',
			// menuEn: 'User Guide',
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
		menuKr() {
			return this.computedValue === 'ko' ? '이용안내' : 'User Guide';
		},
		menuEn() {
			return 'User Guide';
		},
	},
	methods: {
		download(param) {
			downloadService
				.download(param)
				.then(res => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					let fileName = '';
					fileName = res.headers['content-disposition'].split('filename=')[1];
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName); //or any other extension
					document.body.appendChild(link);
					link.click();
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					// whatever
				});
		},
	},
};
</script>

<style></style>
